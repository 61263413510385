import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { FooterComponent } from './shared/components/footer/footer.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './shared/auth/eassist.interceptor';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbDateCustomParserFormatter} from './shared/dateformat';
import { SharedService } from './shared/services/shared.service';
import { LoaderComponent } from './shared/components/loader.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import * as $ from 'jquery';
import { DataTablesModule } from 'angular-datatables';
import { ChartsModule } from 'ng2-charts';
import { BreadcrumbComponent } from './shared/components/breadcrumb.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AuthGuard } from './shared/auth/auth.guard';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({

    declarations: [
        AppComponent,
        FullComponent,
        BlankComponent,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        LoaderComponent,
        BreadcrumbComponent,
    ],

    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        HttpModule,
        BrowserAnimationsModule,   
        FormsModule,
        HttpClientModule,
        NgSelectModule,
        NgbModule.forRoot(),
        ModalModule.forRoot(),
        PerfectScrollbarModule,
        DataTablesModule,
        ChartsModule
    ],

    providers: [  
        { 
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        { 
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter
        },
        SharedService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        AuthGuard,
        Title
    ],

    bootstrap: [AppComponent]

})
export class AppModule { }