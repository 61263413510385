import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SharedService } from '../services/shared.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(
		private router: Router,
		private sharedservice: SharedService
		) {
	}
	
	canActivate(): boolean {
		if (!this.sharedservice.isAuthenticated()) {
			this.sharedservice.clearStorage();
			this.router.navigate(['login']);
			return false;
		}
		return true;
	}
}