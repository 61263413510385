import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullComponent } from './layouts/full/full.component';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './shared/auth/auth.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: '',
        component: FullComponent,
        children: [
            { path: 'wowpoints', loadChildren: './wowpoints/wowpoints.module#WowpointsModule', canActivate: [AuthGuard]},
            { path: 'user', loadChildren: './user/user.module#UserModule', canActivate: [AuthGuard]}
        ]
    },
    {
        path: '',
        component: BlankComponent,
        children: [
            { path: '', loadChildren: './authentication/authentication.module#AuthenticationModule'},
            { path: 'wowpoints/home', loadChildren: './wowpoints/wowpoints.module#WowpointsModule'}
        ]
    },
    {
        path: '**',
        redirectTo: 'wowpoints'
    }
];

@NgModule({
    
    imports: [
        CommonModule,
        RouterModule.forRoot(routes),
        NgbModule.forRoot()
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }