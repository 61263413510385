import { Component, OnInit, HostListener } from '@angular/core';
import { AppSettings } from "../../shared/appSettings";
import { SharedService } from "../../shared/services/shared.service";
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'; 

@Component({
	selector: 'app-full',
	templateUrl: './full.component.html'
})
export class FullComponent implements OnInit {
	
	constructor(
		private sharedService: SharedService
		) {
	}

	userEmail = this.sharedService.getUserDetails().email;
	public config: PerfectScrollbarConfigInterface = {};
	showMinisidebar = false; 
	public innerWidth: any;

	ngOnInit() {
	}


	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.handleLayout();
	}
	toggleSidebar() {
		this.showMinisidebar = !this.showMinisidebar;
		if (this.showMinisidebar) {
			document.querySelector('body').classList.add('sidebar-collapse');
		} else {
			document.querySelector('body').classList.remove('sidebar-collapse');
		}
	}

	handleLayout() {
		this.innerWidth = window.innerWidth;
		if (this.innerWidth < 1170) {
			this.showMinisidebar = true;
			document.querySelector('body').classList.add('sidebar-collapse');
		} else {
			this.showMinisidebar = false;
			document.querySelector('body').classList.remove('sidebar-collapse');
		}
	}
}