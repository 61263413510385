import { Component, ViewEncapsulation, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { SharedService } from '../services/shared.service';
declare var $: any;
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-loader',
    template: `
    <div *ngIf="isLoading" class="pg-loading-screen pg-loading" style="background-color: rgb(254, 209, 78);">
        <div class="pg-loading-inner">
            <div class="pg-loading-center-outer">
                <div class="pg-loading-center-middle">
                    <div class="pg-loading-html pg-loaded" style="margin-top: 0px;">
                        <div class="sk-spinner sk-spinner-chasing-dots" style="width: 50px; height: 50px;">
                            <div class="sk-dot1"></div>
                            <div class="sk-dot2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="preloader" *ngIf="isSpinnerVisible">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
    </div>
    `,
    encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit, OnDestroy {

    constructor(
        private sharedService: SharedService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document
        ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.isSpinnerVisible = true;
            } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.isSpinnerVisible = false;
            }
        }, () => {
            this.isSpinnerVisible = false;
        });
        this.sharedService.isLoading.subscribe(value => {
            this.isLoading = value;
        });
    }

    isLoading = false;
    @Input() public backgroundColor = 'rgba(0, 115, 170, 0.69)';
    public isSpinnerVisible = true;

    ngOnInit() {
        if (this.isLoading) {
            $('.pg-loading-logo-header').removeClass('p.pg-loading-logo-header');
            $('.pg-loading-screen .pg-loading-html').css({"margin-top" : "0px"});
            $('.sk-spinner-chasing-dots.sk-spinner').css({"width" : "50px", "height" : "50px"});
        } else {
            $('body').removeClass('pg-loading');
            $('pg-loading-screen').removeClass('pg-loading-screen');
            $('body.pg-loading').css({"overflow" : "visible"});
        }
    }

    ngOnDestroy() {
        this.isSpinnerVisible = false;
        this.isLoading = false;
    }
}