import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DobMaskDirective } from '../directives/dob-mask.directive';
import { NgInitDirective } from '../directives/ng-init.directive';
import { SearchFilterPipe } from '../pipe/search-filter-pipe';

@NgModule({
    imports: [
        CommonModule
    ],

    exports: [
        DobMaskDirective,
        NgInitDirective
    ],

    declarations: [
        DobMaskDirective,
        NgInitDirective,
        SearchFilterPipe
    ]
})
export class Shared {}