import { Component, AfterViewInit, OnInit, EventEmitter, Output } from '@angular/core';
import * as $ from 'jquery';
import { SharedService } from "../../services/shared.service";
import { Router } from '@angular/router';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit, AfterViewInit {

	constructor(
		private sharedService: SharedService,
		private router: Router
		) {
	}

	@Output() toggleSidebar = new EventEmitter<void>();
	userParameter;
	name;
	firstname;
	lastname;
	membersince;
	avatar_photo;
	notifications = [];

	ngOnInit() {
		this.userParameter = this.sharedService.getUserDetails();
		if (this.userParameter) {
			this.name = this.userParameter.name;
			this.firstname = this.userParameter.firstname;
			this.lastname = this.userParameter.lastname;
			this.membersince = this.userParameter.membersince;
			this.avatar_photo = this.userParameter.picture;
		}
		var avatarImgColor = '#d2d6de';
		let target = $('#drawAlphaImage');
		target.css({"background-color": avatarImgColor});
		let x = '';
		// x = this.firstname + this.lastname;
		x = ( this.firstname ?  this.firstname.charAt(0) : '');
		target.text(x);
		target.css({'align-content':'center'});
		// this.notifications = $rootScope.notifications;
	}

	ngAfterViewInit() {
		var set = function() {
			var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
			var topOffset = 0;
			if (width < 1170) {
				$("body").addClass("sidebar-collapse");
			} else {
				$("body").removeClass("sidebar-collapse");
			}
		};
		$(window).ready(set);
		$(window).on("resize", set);
		$("body").trigger("resize");
	}

	logout() {
		this.sharedService.logoutForm("Do you want to log out ?", () => {
			this.sharedService.clearStorage();
			this.router.navigate(['logout']);
			this.sharedService.success("Logged out successfully");
		});
	};

	toggleProfileDropDown() {
		let profileToggle = document.getElementById("profileToggle").classList.contains("open");
		if (!profileToggle) {
			document.getElementById("profileToggle").classList.add("open");
		} else {
			document.getElementById("profileToggle").classList.remove("open");
		}
	}

	toggleNotificationDropDown() {
		let notifyToggle = document.getElementById("notifyToggle").classList.contains("open");
		if (!notifyToggle) {
			document.getElementById("notifyToggle").classList.add("open");
		} else {
			document.getElementById("notifyToggle").classList.remove("open");
		}	
	}
}