import { environment } from '../../environments/environment';

var API_CONFIG = environment;

export class AppSettings {

	constructor() {}

	public static ACCESS_KEY_ID = "AKIAWKK4CFUWVZ65QYFL";
	public static AWS_REGION = "us-east-1";
	public static SECRET_ACCESS_KEY = "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";
	public static COGNITO_PROTOCOL = "https";
	public static APP_OAUTH_DOMAIN = "sso.eassist.me";
	public static APP_REGION = "us-east-1";
	public static APP_IDENTITY_POOL_ID = "us-east-1:6e79ffa8-913a-4b7c-9942-79ddea75f93b";
	public static APP_API_KEY = "G6bxZ4oiPw4MTSS554Cig1L8DikWTyOJ5Jo484C3";
	public static APP_SOCKET_API = "wss://0x6xam7n30.execute-api.us-east-1.amazonaws.com/test?";
	public static APP_POINTS_SOCKET_API = "wss://u4xr4l201b.execute-api.us-east-1.amazonaws.com/dev?";

	public static APP_BASE_URL = API_CONFIG.APP_BASE_URL;
	public static APP_SIGNIN_CALLBACK = API_CONFIG.APP_SIGNIN_CALLBACK;
	public static APP_SIGNOUT_CALLBACK = API_CONFIG.APP_SIGNOUT_CALLBACK;
	public static APP_USER_POOL_ID = API_CONFIG.APP_USER_POOL_ID;
	public static APP_CLIENT_ID = API_CONFIG.APP_CLIENT_ID;
	public static APP_STAGE = API_CONFIG.APP_STAGE;
	public static APP_API_HOST = API_CONFIG.APP_API_HOST;

	public static IS_STATIC = false;
	public static API_ENDPOINT = "https://" + AppSettings.APP_API_HOST + '/' + AppSettings.APP_STAGE + '/';
	
	//Pre-Warmed  
	public static CHECKUSERACCESS = AppSettings.API_ENDPOINT + 'user/checkUserAccess';
	public static GETUSERDETAIL = AppSettings.API_ENDPOINT + 'user/getUserDetails';
	public static GETUSERINFO = AppSettings.API_ENDPOINT + 'user/users';

	//WowPoints
	public static MYPOINTS = AppSettings.API_ENDPOINT + 'wowpoints/mypoints';
	public static ADDROLEMODULE = AppSettings.API_ENDPOINT + 'rolepermission/addRoleModule';
	public static ACCEPTED_TOS = AppSettings.API_ENDPOINT + 'wowpoints/accepted_tos';
	public static READNOTIFICATION = AppSettings.API_ENDPOINT + 'wowpoints/readnotification';
	public static CHECK_ALLOWED_WOWPOINTS = AppSettings.API_ENDPOINT + 'wowpoints/check_allowed_wowpoints';
	public static UPDATE_WOWPOINT_INTRO = AppSettings.API_ENDPOINT + 'wowpoints/update_wowpoint_intro';
	public static ACHIEVEMENTS = AppSettings.API_ENDPOINT + 'wowpoints/achievements';
	public static GROUPS = AppSettings.API_ENDPOINT + 'wowpoints/groups';
	public static WOWPOINTACHIEVEMENTS = AppSettings.API_ENDPOINT + 'wowpoints/wowpointAchievements';
	public static WOWPOINTACHIEVEMENTQUESTIONS = AppSettings.API_ENDPOINT + 'wowpoints/wowpointAchievementQuestions';
	public static WOWPOINTSANSWERS = AppSettings.API_ENDPOINT + 'wowpoints/wowPointsAnswers';
	public static EARNWOWPOINTS = AppSettings.API_ENDPOINT + 'earnWowPoints';
	public static TRANS_STATEMENT = AppSettings.API_ENDPOINT + 'wowpoints/trans_statement';
	public static REWARD_TRANS_STATEMENT = AppSettings.API_ENDPOINT + 'wowpoints/reward_trans_statement';
	public static GETLEDGERPOINTSREPORT = AppSettings.API_ENDPOINT + 'wowpoints/getLedgerPointsReport';
	public static REDEMPTIONHISTIORYLIST = AppSettings.API_ENDPOINT + 'wowpoints/redemptionHistioryList';
	public static REDEEMCONFIRMATION = AppSettings.API_ENDPOINT + 'wowpoints/redeemconfirmation';
	public static ADDREDEMPTIONREQUEST = AppSettings.API_ENDPOINT + 'wowpoints/addredemptionrequest';

	public static REPORTOFPOINTSANDREWARD = AppSettings.API_ENDPOINT + 'wowpoints/reportOfPointsAndReward';
	public static USERSLIST = AppSettings.API_ENDPOINT + 'user/usersList';
	
	public static PENDINGACHIEVEMENTS = AppSettings.API_ENDPOINT + 'wowpoints/PendingAchievements';
	public static PENDINGANDAPPROVEDONCE = AppSettings.API_ENDPOINT + 'wowpoints/PendingAndApprovedOnce';
	public static APPROVEWOWPOINTSREQUEST = AppSettings.API_ENDPOINT + 'wowpoints/approveWowPointsRequest';
	public static APPROVALQUESTIONANSWERS = AppSettings.API_ENDPOINT + 'wowpoints/approvalQuestionAnswers';
	public static APPROVEALLWOWREQUEST = AppSettings.API_ENDPOINT + 'wowpoints/approveAllWowRequest';
	public static QUARTERLYCONVERSIONADMINISTRATIVE = AppSettings.API_ENDPOINT + 'wowpoints/quarterlyConversionAdministrative';
	public static CONVERSIONRATE = AppSettings.API_ENDPOINT + 'wowpoints/conversionrate';
	public static DATAFORCONVERSION = AppSettings.API_ENDPOINT + 'wowpoints/dataForConversion';
	public static GETREDEMPTIONREQUESTS = AppSettings.API_ENDPOINT + 'wowpoints/getRedemptionRequests';
	public static APPROVEREJECT_REQUEST = AppSettings.API_ENDPOINT + 'wowpoints/approvereject_request';
	public static ADMINAWARDLISTING = AppSettings.API_ENDPOINT + 'wowpoints/adminawardlisting';
	public static CANCELONETIMEAWARD = AppSettings.API_ENDPOINT + 'wowpoints/cancelonetimeaward';
	public static ADDONETIMEAWARD = AppSettings.API_ENDPOINT + 'wowpoints/addonetimeaward';
	public static GETCONVERSIONREQUEST = AppSettings.API_ENDPOINT + 'wowpoints/getconversionrequest';
	public static APPROVE_REJECT_CONVERSIONRATE = AppSettings.API_ENDPOINT + 'wowpoints/approve_reject_conversionrate';
	public static MULTIPLEUSERADDACHIEVEMENT = AppSettings.API_ENDPOINT + 'wowpoints/multipleUserAddAchievement';
	public static DELETIONREQUEST = AppSettings.API_ENDPOINT + 'wowpoints/deletionRequest';
	public static UPDATE_WOWPOINTS_DATE = AppSettings.API_ENDPOINT + 'wowpoints/update_wowpoints_date';
	public static GETDELETIONREQUESTLIST = AppSettings.API_ENDPOINT + 'wowpoints/getDeletionRequestList';
	public static APPROVEREJECT_DELETIONREQUEST = AppSettings.API_ENDPOINT + 'wowpoints/approveReject_deletionRequest';
	public static REDEEMPTION_REQUEST_LIST = AppSettings.API_ENDPOINT + 'wowpoints/get_wow_redemption_request_list';

	public static GETALLROLES = AppSettings.API_ENDPOINT + 'rolepermission/GetAllRoles';
	public static ADDUSERROLES = AppSettings.API_ENDPOINT + 'rolepermission/AddUserRoles';
	public static CHANGEROLESTATUS = AppSettings.API_ENDPOINT + 'rolepermission/ChangeRoleStatus';
	public static GETALLMODULES = AppSettings.API_ENDPOINT + 'rolepermission/getAllModules';
	public static UPDATEMODULE = AppSettings.API_ENDPOINT + 'rolepermission/updateModule';
	public static GETALLSUBMODULES = AppSettings.API_ENDPOINT + 'rolepermission/getAllSubmodules';
	public static ADDSUBMODULE = AppSettings.API_ENDPOINT + 'rolepermission/addSubmodule';
	public static UPDATESUBMODULE = AppSettings.API_ENDPOINT + 'rolepermission/updateSubmodule';
	public static GETALLACTIVITY = AppSettings.API_ENDPOINT + 'rolepermission/getAllActivity';
	public static ADDACTIVITY = AppSettings.API_ENDPOINT + 'rolepermission/addActivity';
	public static UPDATEACTIVITY = AppSettings.API_ENDPOINT + 'rolepermission/updateActivity';
	public static GETUSERROLEPERMISSIONS = AppSettings.API_ENDPOINT + 'rolepermission/getUserRolePermissions';
	public static ASSIGNUSERROLEPERMISSION = AppSettings.API_ENDPOINT + 'rolepermission/assignUserRolePermission';
	public static GETALLMODULESLIST = AppSettings.API_ENDPOINT + 'rolepermission/getAllModulesList';
	public static ADDROLEPERMISSION = AppSettings.API_ENDPOINT + 'rolepermission/addRolePermission';
	
	public static GET_USER_LIST = AppSettings.API_ENDPOINT + 'user/usersList'
	public static CHECKUSEREXIST = AppSettings.API_ENDPOINT + 'user/checkuserexistence';
	public static MDOULEPERMISSIONS = AppSettings.API_ENDPOINT + 'rolepermission/getRolePermissions';
	public static GET_ALL_USER_LIST = AppSettings.API_ENDPOINT + 'user/getuserslist';
	public static GET_USERS_LIST = AppSettings.API_ENDPOINT + 'user/getuserslist';
	public static GET_ALL_ROLES_LIST = AppSettings.API_ENDPOINT + 'rolepermission/GetAllRoles';
	public static ASSIGN_USER_ROLES = AppSettings.API_ENDPOINT + 'rolepermission/assignUserRolePermission';
	public static GET_USER_ROLE_PERMISSION_BY_ID = AppSettings.API_ENDPOINT + 'rolepermission/getUserRolePermissionsByUid';
	public static DELETE_SUBMODULE = AppSettings.API_ENDPOINT + 'rolepermission/deleteSubModule';
	public static DELETE_MODULE = AppSettings.API_ENDPOINT + 'rolepermission/deleteModule';
	public static DELETE_ACTIVITY = AppSettings.API_ENDPOINT + 'rolepermission/deleteActivity';
	public static GET_ALL_PAGES = AppSettings.API_ENDPOINT + 'rolepermission/getAllPagesSubmodules';
	public static DELETE_PAGES = AppSettings.API_ENDPOINT + 'rolepermission/deletePagesSubmodule';

	//ROLE VALUES
	public static ROLE_VALUES = {
		CLIENT: 1,
		STAFF: 2,
		AE: 4,
		AM: 3,
		ADMIN: 10
	};

	//ROLES
	public static ROLES = ["CLIENT", "STAFF", "CLevel", "TM", "SOS", "AE", "KC", "AM"];
	
	public static getTodayDate() {return new Date()}; 

	public static ROLES_PERMISSIONS = {
		"wowpoints.view": ["STAFF"],
		"achievements.view": ["STAFF"],
		"achievements.create": ["STAFF"],
		"achievements.edit": ["STAFF"],
		"user-profile.view": ["CLIENT", "STAFF"],
		"pending-insurance-plan.view" : ["STAFF"],
		"self-report.view" : ["STAFF"],
		"redemption.view" : ["STAFF"],
		"wow_points_achievement.view": ["STAFF"],
		"wowpointsapproval.view": ["STAFF"],
		"wowpointsawards.view": ["STAFF"],
		"revokeawards.view": ["STAFF"],
		"programstats.view": ["STAFF"],
		"quarterprofit.view": ["STAFF"],
		"redemptionrequest.view" : ["STAFF"],
		"rewardpoints.view" : ["STAFF"],
		"statements.view" : ["STAFF"],
		"reports.view" : ["STAFF"],
		"role-permission.view": ["CLIENT", "STAFF"],
		"user-role.view": ["CLIENT", "STAFF"],
		"user-module.view": ["CLIENT", "STAFF"],
		"user-submodule.view": ["CLIENT", "STAFF"],
		"user-activity.view": ["CLIENT", "STAFF"],
		"user-assign-role.view": ["CLIENT", "STAFF"],
	};

	public static WOWPOINTAPPROVAL_ACCESS  = [4789,3791];
}