import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import * as crypto from 'crypto-js';
import { SharedService } from '../services/shared.service';
import { finalize } from "rxjs/operators";
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

var credAuth = {
    accessKeyId: "AKIAWKK4CFUWVZ65QYFL",
    region: "us-east-1",
    secretAccessKey: "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",
    apiKey: "G6bxZ4oiPw4MTSS554Cig1L8DikWTyOJ5Jo484C3"
};

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private sharedService :SharedService,
        private router: Router
        ) {
    }

    toUrl(url) {
        var a = document.createElement('a'); a.href = url;
        return a;
    }

    execute(credentials, request) {
        var date = new Date();
        var url = this.toUrl(request.url);
        credentials['host'] = url.host;
        request['route'] = url.pathname;
        var canonical = this.canonicalRequest(credentials, request, date);
        var toSign = this.requestToSign(canonical, credentials, date);
        var signature = this.signature(toSign, credentials, date);
        return {
            'x-amz-date': this.amzLongDate(date),
            'Authorization': 'AWS4-HMAC-SHA256 Credential=' + credentials.accessKeyId + '/' + this.amzShortDate(date) + '/' + credentials.region + '/execute-api/aws4_request, ' + ('SignedHeaders=host;range;x-amz-date' + ', Signature=' + signature),
            'x-api-key': credentials.apiKey
        };
    }

    canonicalRequest(credentials, request, date) {
        return request.method.toUpperCase() + '\n' + (request.route.charAt(0) !== '/' ? '/' + request.route : request.route) + '\n' + this.queryParameters(request.query) + '\nhost:' + credentials.host + '\n' + ('range:' + '\n') + ('x-amz-date:' + this.amzLongDate(date) + '\n') + (credentials.token ? 'x-amz-security-token:' + credentials.token + '\n' : '') + '\n' + ('host;range;x-amz-date' + '\n') + this.hashString(request.body);
    }

    requestToSign(cRequest, credentials, date) {
        return 'AWS4-HMAC-SHA256\n' + this.amzLongDate(date) + '\n' + this.amzShortDate(date) + '/' + credentials.region + '/execute-api/aws4_request\n' + this.hashString(cRequest);
    }

    signature(toSign, credentials, date) {
        return this.hmac(this.hmac(this.hmac(this.hmac(this.hmac('AWS4' + credentials.secretAccessKey, this.amzShortDate(date)), credentials.region), 'execute-api'), 'aws4_request'), toSign).toString();
    }

    queryParameters(queryParameterObj) {
        var pieces = [];
        if (queryParameterObj) {
            Object.keys(queryParameterObj).sort().forEach(function (k) {
                return pieces.push(k + '=' + encodeURIComponent(queryParameterObj[k]));
            });
        }
        return pieces.length > 0 ? pieces.join('&') : '';
    }

    hashString(str) {
        return crypto.SHA256(str).toString();
    }

    hmac(key, value) {
        return crypto.HmacSHA256(value, key);
    }

    amzShortDate(date) {
        return this.amzLongDate(date).substr(0, 8);
    }

    amzLongDate(date) {
        return date.toISOString().replace(/[:\-]|\.\d{3}/g, '').substr(0, 17);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var newRequestObj = null;
        if (request.method == 'GET') {
            let url = request.url;
            var urlSplit = url.split('?');
            if (urlSplit.length > 1) {
                newRequestObj = {
                    url: urlSplit[0],
                    method: 'GET',
                    query: {}
                };
                if (urlSplit[1]) {
                    let paramArr = urlSplit[1].split('&');
                    if (paramArr.length) {
                        for (let i = 0; i < paramArr.length; i++) {
                            if (paramArr[i]) {
                                let newKeyValue = (paramArr[i]).split('=');
                                if (newKeyValue.length) {
                                    newRequestObj['query'][newKeyValue[0]] = newKeyValue[1];
                                }
                            }
                        }
                    }
                }
            }
        }
        if(!newRequestObj) {
            const increptedHeaders = this.execute(credAuth, request);
            const newheaders = {
                "Authorization": increptedHeaders.Authorization,
                "x-amz-date":increptedHeaders['x-amz-date'],
                "x-api-key": increptedHeaders['x-api-key'],
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json"
            };
            request = request.clone({
                setHeaders: newheaders
            });
        } else {
            var headers = this.execute(credAuth, newRequestObj);
            request = request.clone({
                setHeaders: headers
            });
        }
        setTimeout(() => {this.sharedService.show();}, 0)
        return next.handle(request).pipe(
            retry(0), 
            catchError(
                (error: HttpErrorResponse) => 
                {
                    if (error.status == 403) {
                        this.sharedService.clearStorage();
                        this.router.navigate(['login']);
                    }
                    return throwError(error);
                }
            )
        ).pipe(finalize(() => setTimeout(() => {this.sharedService.hide();}, 0)) );
    }
}