import { Injectable } from '@angular/core';
import { AppSettings } from '../appSettings';
import { HttpClient } from '@angular/common/http';
import { ResponseData } from '../response';
import { Subject, BehaviorSubject } from 'rxjs';
import * as _swal from 'sweetalert';
import { SweetAlert } from 'sweetalert/typings/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

const swal: SweetAlert = _swal as any;

@Injectable({
	providedIn: 'root'
})
export class SharedService {

	constructor(
		private router: Router,
		private http: HttpClient,
		private titleService: Title,
		private activatedRoute: ActivatedRoute
		) {
	}

	isLoadingCount = 0;
	isLoading = new Subject<boolean>();

	public dayWiseData: any = [];
	public subject = new Subject<any>();
	private dayWisedetailSource = new  BehaviorSubject(this.dayWiseData);
	currentDaywiseData	 = this.dayWisedetailSource.asObservable();

	successCallback(message, callback) {
		swal({
			title: message,
			icon: 'success',
		}).then((result) => {
			if (result) {
				callback();
			}
		});
	}

	errorCallback(message, callback) {
		swal({
			title: message,
			icon: 'error'
		}).then((result) => {
			callback();
		});
	}

	confirmDelete(message, callback) {
		swal({
			text: message,
			icon: 'warning',
			buttons : {
				cancel : {
					text: "Cancel",
					value: false,
					visible: true,
					className: "swalCancelBtn",
					closeModal: true,
				},
				confirm : {
					text: "Yes",
					value: true,
					visible: true,
					className: "swalConfirmBtn",
					closeModal: true,
				}
			},
			closeOnClickOutside: false,
			closeOnEsc: false,
		}).then((result) => {
			if (result) {
				callback(true);
			}
		});
	}

	confirm(message, callback) {
		swal({
			title: message,
			icon: 'warning',
			buttons : {
				cancel : {
					text: "Cancel",
					value: false,
					visible: true,
					className: "swalCancelBtn",
					closeModal: true,
				},
				confirm : {
					text: "Yes",
					value: true,
					visible: true,
					className: "swalConfirmBtn",
					closeModal: true,
				}
			},
			closeOnClickOutside: false,
			closeOnEsc: false,
		}).then((result) => {
			if (result) {
				callback(true);
			} else {
				callback(false);
			}
		});
	}

	logoutForm(message, callback) {
		swal({
			title: message,
			icon: 'warning',
			buttons : {
				cancel : {
					text: "Cancel",
					value: false,
					visible: true,
					className: "swalCancelBtn",
					closeModal: true,
				},
				confirm : {
					text: "Yes",
					value: true,
					visible: true,
					className: "swalConfirmBtn",
					closeModal: true,
				}
			},
			closeOnClickOutside: false,
			closeOnEsc: false,
		}).then((result) => {
			if (result) {
				callback();
			}
		});
	}

	getFixedUpto(value, num = 2) {
		if (value) {
			return value.toFixed(num)
		} else {
			return '0.00';
		}
	}

	success(message) {
		swal({
			title: message,
			icon: 'success',
		});
	}

	error(message = 'Something went wrong!') {
		swal({
			title: message,
			icon: 'error',
		});
	}

	unAuthorize(data) {
		if (data && data.status !== 500) {
			localStorage.clear();
			sessionStorage.clear();
			this.router.navigate(['/logout']);
		}
	}

	downloadLink(message, callback) {
		swal({
			title: 'Download?',
			text: message,
			icon: 'info',
			buttons : {
				cancel : {
					text: "Cancel",
					value: false,
					visible: true,
					className: "swalCancelBtn",
					closeModal: true,
				},
				confirm : {
					text: "Yes",
					value: true,
					visible: true,
					className: "swalConfirmBtn",
					closeModal: true,
				}
			},
			closeOnClickOutside: false,
			closeOnEsc: false,
		}).then((result) => {
			if (result) {
				callback();
			}
		});
	}

	getAdditionalParameter(current_this, isMode = false) {
		var str_parameters = "";
		if (!isMode) {
			str_parameters = "?"; 
		}
		if (current_this.group_id != undefined && current_this.group_id != '') {
			str_parameters += "group_id="+current_this.group_id
		}
		if (current_this.current_page != undefined && current_this.current_page != '') {
			str_parameters += "&page="+current_this.current_page
		}
		if (current_this.col != undefined && current_this.col != '') {
			str_parameters += "&sort_by="+current_this.col;
		}
		if (current_this.orderpattern != undefined && current_this.orderpattern != '') {
			str_parameters += "&sort_direction="+current_this.orderpattern;
		}
		if (current_this.search_data != undefined && current_this.search_data != '') {
			str_parameters += "&keyword="+current_this.search_data;
		}
		if (current_this.limit != undefined && current_this.limit != '') {
			str_parameters += "&limit="+current_this.limit;
		}	
		if (current_this.listOptions.limit != undefined && current_this.listOptions.limit != '') {
			str_parameters += "&limit="+current_this.listOptions.limit;
		}	
		if (current_this.listOptions.limit != undefined && current_this.listOptions.limit != '') {
			const skip = (current_this.listOptions.pageNo - 1) * current_this.listOptions.limit
			str_parameters += "&skip="+skip;
		}	
		if (current_this.LastEvaluatedKey != undefined && current_this.LastEvaluatedKey != '') {
			str_parameters += "&LastEvaluatedKey="+current_this.LastEvaluatedKey;
		}	
		if (current_this.payer_id  != undefined && current_this.payer_id  != '') {
			str_parameters += "&payer_id ="+current_this.payer_id;
		}
		if (localStorage.getItem('useraccountid') != undefined && localStorage.getItem('useraccountid') != '') {
			str_parameters +="&uid="+localStorage.getItem('useraccountid')	
		}
		if (localStorage.getItem('userToken') != undefined && localStorage.getItem('userToken') != '') {
			str_parameters +="&userToken="+ localStorage.getItem('userToken');
		}
		if (current_this.officeid != undefined && current_this.officeid != '') {
			str_parameters +="&officeid="+ current_this.officeid;
		}
		return str_parameters;
	}

	getToken() {
		var tokenString = "?"; 
		tokenString += "userToken="+localStorage.getItem('userToken');
		tokenString +="&uid="+localStorage.getItem('useraccountid')	
		return tokenString
	}

	setUserData(userData, isProxyUserData = false) {
		if(isProxyUserData) {
			localStorage.setItem('proxy_role', '2');
			localStorage.setItem('proxy_email', userData.email.toString().toLowerCase());
			localStorage.setItem('proxy_firstname', userData.firstname);
			localStorage.setItem('proxy_lastname', userData.lastname);
			// localStorage.setItem('proxy_membersince', userData.membersince);
			localStorage.setItem('proxy_useraccountid', userData.useraccountid);
			localStorage.setItem('proxy_userToken', userData.userToken);
			localStorage.setItem('proxy_picture', userData.UserPicture);
			localStorage.setItem('proxy_roles', JSON.stringify(userData.roles));
			localStorage.setItem('proxy_module_permissions', JSON.stringify({}));
		} else {
			localStorage.setItem('role', '2');
			localStorage.setItem('email', userData.email.toString().toLowerCase());
			localStorage.setItem('firstname', userData.firstname);
			localStorage.setItem('lastname', userData.lastname);
			// localStorage.setItem('membersince', userData.membersince);
			localStorage.setItem('useraccountid', userData.useraccountid);
			localStorage.setItem('picture', userData.UserPicture);
			localStorage.setItem('userToken', userData.userToken);
			localStorage.setItem('roles', JSON.stringify(userData.roles));
			localStorage.setItem('module_permissions', JSON.stringify({}));
		}
	}

	isEmpty(obj) {
		for (var key in obj) {
			if (obj.hasOwnProperty(key))
				return false;
		}
		return true;
	}

	checkIsLandingPage() {
		var url = window.location.href;
		var lastPartUrl = url.substring(url.lastIndexOf('/') + 1);
		return (lastPartUrl === 'home' ? true : false);
	}

	getUserDetails() {
		var userObj = {
            'roleId' : parseInt(localStorage.getItem('role')),
            'uid': parseInt(localStorage.getItem('useraccountid')),
            'email': localStorage.getItem('email'),
            'firstname': localStorage.getItem('firstname'),
            'lastname': localStorage.getItem('lastname'),
            'roles': JSON.parse(localStorage.getItem('roles')),
            'module_permissions': JSON.parse(localStorage.getItem('module_permissions')),
            'picture': localStorage.getItem('picture'),
            'role': localStorage.getItem('role'),
            'name': localStorage.getItem('name'),
            'userToken':localStorage.getItem('userToken')
        };
        if (localStorage.getItem('proxy_useraccountid')) {
            let userRole = JSON.parse(localStorage.getItem('roles'));
            if (userRole.cleveluser) {
                userObj = {
                    'roleId' : parseInt(localStorage.getItem('proxy_role')),
                    'uid': parseInt(localStorage.getItem('proxy_useraccountid')),
                    'email': localStorage.getItem('proxy_email'),
                    'firstname': localStorage.getItem('proxy_firstname'),
                    'lastname': localStorage.getItem('proxy_lastname'),
                    'roles': JSON.parse(localStorage.getItem('proxy_roles')),
                    'module_permissions': JSON.parse(localStorage.getItem('proxy_module_permissions')),
                    'picture': localStorage.getItem('proxy_picture'),
                    'role': localStorage.getItem('proxy_role'),
                    'name': localStorage.getItem('proxy_name'),
                    'userToken':localStorage.getItem('proxy_userToken')
                };
            } else{
                 localStorage.removeItem('proxy_useraccountid');
            }
        }
        return userObj;
   	}

	isEmptyInput(inputData) {
		if (inputData) {
			return ( (Object.keys(inputData).length === 0) ? true : false);     
		}
	}

	getCurrentUserRole(role = null) {
		const userDetails = this.getUserDetails();
		if (userDetails) 
			return userDetails.roleId;
		return false;
	}

	isUserRole(role) {
		return (this.getCurrentUserRole() == role);
	}

	isUserAE() {
		return this.isUserRole(AppSettings.ROLE_VALUES.AE);
	}

	isUserAM() {
		return this.isUserRole(AppSettings.ROLE_VALUES.AM);
	}

	isUserKC() {
		// return this.isUserRole(this.ROLE_VALUES.KC);
		return true;
	}

	isUserAdmin() {
		return (!this.isAdditinalRoleEassister() ? true : false);
	}

	userDetailsFromJWT() {
		let token = localStorage.getItem('userToken');
		if (token) {
			var base64Url = token.split('.')[1];
			var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));
			return JSON.parse(jsonPayload);
		}
	}

	getUserParameters () {
		var userToken = localStorage.getItem('userToken');
		var userDetails = this.getUserDetails();
		var uid = (userDetails ? userDetails.uid : '');
		var email = (userDetails ? userDetails.email.toString().toLowerCase() : localStorage.getItem('email'));

		return {
			'userToken' : userToken,
			'uid' : uid,
			'email' : email
		}
	}

	convertDateToUTC (date) {
		return new Date(date);
	}

	addAnchorTag(inputText) {
		let replacedText = '';
		if (inputText) {
			//URLs starting with http://, https://, or ftp://
			let replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
			replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
		}
		return replacedText;
	}

	getAdditionalRole() {
		const userDetails = this.getUserDetails();
		return userDetails.roles;
	}

	isAdditinalRoleRedeemAdmin() {
		let objectRoles = this.getAdditionalRole();
		return (objectRoles && objectRoles.redeemadmin ? true : false);
	}

	isAdditinalRoleGeneralAdmin() {
		let objectRoles = this.getAdditionalRole();
		return (objectRoles && objectRoles.generaladmin ? true : false);
	}

	isAdditinalRoleDataEntry() {
		let objectRoles = this.getAdditionalRole();
		return (objectRoles && objectRoles.dataentry ? true : false);
	}

	isAdditinalRoleEassister() {
		let objectRoles = this.getAdditionalRole();
		return (objectRoles && objectRoles.eassister ? true : false);
	}

	getModulePermissions() {
		const userDetails = this.getUserDetails();
		return userDetails.module_permissions;
	}

	isWritePermission(moduleName) {
		let objectModulePermission = this.getModulePermissions();
		if (objectModulePermission && objectModulePermission[moduleName] != undefined  && objectModulePermission[moduleName] == 'write') {
			return true;
		} 
		return false;
	}

	isReadPermission(moduleName) {
		let objectModulePermission = this.getModulePermissions();
		if (objectModulePermission && objectModulePermission[moduleName] != undefined) {
			return true;
		} 
		return false;
	}

	compareDate(date1: Date, date2: Date): number {
		// With Date object we can compare dates them using the >, <, <= or >=.
		// The ==, !=, ===, and !== operators require to use date.getTime(),
		// so we need to create a new instance of Date with 'new Date()'
		let d1 = new Date(date1);
		let d2 = new Date(date2);
		// Check if the dates are equal
		let same = d1.getTime() === d2.getTime();
		if (same) return 0;

		// Check if the first is greater than second
		if (d1 > d2) return 1;

		// Check if the first is less than second
		if (d1 < d2) return -1;
	}

	dateToStr(date) {
		if (date && date != '') {
			let newDate;
			if (date && date.day) {
				newDate = new Date(date.year, (date.month - 1), date.day);
			} else {
				newDate = new Date(date);
			}
			return newDate;
		} else {
			return '';
		}
	}

	dateObj(date: Date) {
		let d1 = new Date(date);
		const day = d1.getDate();
		const month = d1.getMonth() + 1;
		const year = d1.getFullYear();
		return {
			day: day,
			month: month,
			year: year
		};
	}

	getData(url): Promise<ResponseData[]> {  
		return this.http.get(url).toPromise().then((data : any) => this.extractData(data)).catch(err => {
			return Promise.reject(err.error || 'Server error');
		});
	}

	extractData(data) { 
		return Promise.resolve(data);
	}

	show() {
		this.isLoadingCount = this.isLoadingCount + 1;
		this.isLoading.next(this.isLoadingCount ? true : false);
	}

	hide() {
		this.isLoadingCount = this.isLoadingCount - 1;
		this.isLoading.next(this.isLoadingCount ? true : false);
	}

	getTodayDate() {
		let d1 = new Date();
		const day = d1.getDate();
		const month = d1.getMonth() + 1;
		const year = d1.getFullYear();
		return {
			day: day,
			month: month,
			year: year
		};
	}

	getFormattedDate(date = null, convertFromDate = false) {
		let day = '';
		let month = '';
		let year = '';
		if (!convertFromDate) {
			year = date.year.toString();
			month = date.month.toString().padStart(2, '0');
			day = date.day.toString().padStart(2, '0');
		} else {
			let dateObj = new Date(date);
			year = dateObj.getFullYear().toString();
			month = (1 + dateObj.getMonth()).toString().padStart(2, '0');
			day = dateObj.getDate().toString().padStart(2, '0');
		}
		const formattedDate = `${year}/${month}/${day}`;
		return formattedDate;
	}

	userHasPermission(data) {
		let ROLES_PERMISSIONS = AppSettings.ROLES_PERMISSIONS;
		if (!data) return true;
		let roleArray = [];
		roleArray = this.init();

		if (data == undefined) {
			return true;
		}
		if (ROLES_PERMISSIONS[data] == undefined) return false;
		let found = false;
		roleArray.forEach(role => {
			if (ROLES_PERMISSIONS[data].indexOf(role) >= 0) found = true;
		});
		return found;
	}

	userHasClientRole() {
		let roleArray = [];
		roleArray = this.init();

		let result = false;
		roleArray.forEach(role => {
			if (role == "CLIENT") result = true;
		});
		return result;
	}

	userHasStaffRole() {
		let roleArray = [];
		roleArray = this.init();

		var result = false;
		roleArray.forEach(role => {
			if (role == "STAFF") result = true;
		});
		return result;
	}

	init() {
		let ROLES = AppSettings.ROLES;
		let roleValue;
		let roleArray = [];
		roleValue = localStorage.getItem('role');
		for (let i = 0; i < ROLES.length; i++) {
			if (roleValue & Math.pow(2, i)) {
				roleArray.push(ROLES[i]);
			}
		}
		return roleArray;
	}

	historyBack = () => window.history.back();
	
	getFormDataWithToken(formData) {
		return {
			data : formData, 
			userToken: this.getUserDetails().userToken, 
			uid: this.getUserDetails().uid
		}
	}

	info(message) {
		swal({
			title: message,
			icon: 'info',
		});
	}

	setLandingPage() {
		var url = window.location.href;
		var lastPartUrl = url.substring(url.lastIndexOf('/') + 1);
		const boolValue = (lastPartUrl === 'home' ? 'yes' : 'no');
		localStorage.setItem('isLandingPage', boolValue);
	}

	warning(message) {
		swal({
			title: message,
			icon: 'warning',
		});
	}

	clearStorage() {
		localStorage.clear();
		sessionStorage.clear();
	}

	isAuthenticated(){
		return (this.getUserDetails().userToken ? true : false); 
	}

	redirectTo(routeLink){
		this.router.navigate([routeLink]);
	}

	isValueEmpty(array, key){
		if (array.length > 0) {
			for(let i = 0; i < array.length; i++){ 
				if(!array[i][key]){ 
					return true;
					break;
				} 
			}
			return false;
		}
	}
	
	ArrdynamicSort(property) {
	    var sortOrder = 1;
	    if(property[0] === "-") {
	      sortOrder = -1;
	      property = property.substr(1);
	    }
	    return function (a,b) {
	      if(sortOrder == -1){
	        return b[property].localeCompare(a[property]);
	      }else{
	        return a[property].localeCompare(b[property]);
	      }        
	    }
  	}

  	isDocDashboard(){
  		return (localStorage.getItem('docdashboard') && localStorage.getItem('docdashboard') == 'yes' ? true : false);
  	}

  	addDayWiseData(dayData) {
		this.dayWisedetailSource.next(dayData);
	}

	isClevelUser(){
        let objectRoles = this.getAdditionalRole();
        return ( (objectRoles.cleveluser)? true : false);
    }

    isCurrentUserProxy (){
        return (localStorage.getItem('proxy_useraccountid') ? true : false);
    }

    isEassistLogin (email = null){
        if (!email) {
            const userDetails = this.getUserDetails();
            email = userDetails.email;
        }
        var domain = email.substring(email.lastIndexOf("@") +1);
        return (domain.toLowerCase() == 'eassist.me'); 
    }
    
    redirectToWowPoint(){
    	window.location.href = `${location.origin}/wowpoints`;
    }

    isOnboardingAccess(){
        let objectRoles = this.getAdditionalRole();
        return ( (objectRoles.CCuser || objectRoles.ITuser || objectRoles.IVsupervisor || objectRoles.ivadmin || objectRoles.accountingmanager || objectRoles.cleveluser || objectRoles.salesuser) ? true : false);
    }

    copyToClipboard(name= null) {
        var copyElement = document.createElement("textarea");
        copyElement.style.position = 'fixed';
        copyElement.style.opacity = '0';
        copyElement.textContent = decodeURI(name);
        var body = document.getElementsByTagName('body')[0];
        body.appendChild(copyElement);
        copyElement.select();
        document.execCommand('copy');
        body.removeChild(copyElement);
        return true;
    }

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ 
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ 
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ 
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ 
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ 
          '(\\#[-a-z\\d_]*)?$','i');
        return !!pattern.test(str);
    }

    restrictToDecimal(keyCode, value) {
        let correctValue = null;
        // on keyup check for backspace & delete, to allow user to clear the input as required
        if (keyCode == 8 || keyCode == 46) {
            return null;
        };
        if (value) {
            // get the current input value
            correctValue = value.toString();

             //if there is no decimal places add trailing zeros
            if (correctValue.indexOf('.') === -1) {
                correctValue += '.00';
            }
            else {
                //if there is only one number after the decimal add a trailing zero
                if (correctValue.toString().split(".")[1].length === 1) {
                    correctValue += '0'
                }
                //if there is more than 2 decimal places round backdown to 2
                if (correctValue.toString().split(".")[1].length > 2) {
                    correctValue = parseFloat(value).toFixed(2).toString();
                }
            }
        }
        return correctValue;
    }

    setPageTitle(title = null){
    	const appTitle = this.titleService.getTitle();
	    this.router
	      .events.pipe(
	        filter(event => event instanceof NavigationEnd),
	        map(() => {
	          const child = this.activatedRoute.firstChild;
	          if (child.snapshot.data['title']) {
	            return child.snapshot.data['title'];
	          }
	          return appTitle;
	        })
	      ).subscribe((ttl: string) => {
	        this.titleService.setTitle(ttl);
	      });

    	title = `eAssist Dental Billing |  ${decodeURI(title)}`;
    	this.titleService.setTitle(title);
    }
}