import { Component, OnInit } from '@angular/core';
import { SharedService } from "../../services/shared.service";
import { Router } from '@angular/router';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

	constructor(
		public sharedService: SharedService,
		private router: Router
		) {
	}

	url_segments = [];
	isDashboard = false;

	ngOnInit() {
		this.url_segments = this.router.url.split('/');
		this.checkIsDocDashboard();
	}

	checkIsDocDashboard(){
		this.isDashboard = (this.url_segments[1] == 'docdashboard' ? true : false);
	}

	redirectTo(path, isDashboard = null){
		this.isDashboard = isDashboard;
		this.router.navigate([path]);
	}

	backToClevel (){
        localStorage.removeItem('proxy_useraccountid');
        this.sharedService.redirectToWowPoint();
    }
}